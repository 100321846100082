import React from "react"
import { Link } from "gatsby"

const styles = require("./Sidebar.module.scss")

const Logo: React.FunctionComponent = () => {
  return (
    <Link to="/">
      <div className={styles.Logo}>
        <svg xmlns="http://www.w3.org/2000/svg" width="156.474" height="30" viewBox="0 0 156.474 30">
          <g>
            <path
              d="M52.292,26.72V2.838h4.952c4.859,0,9.212,1.672,9.212,7.067,0,4.575-2.9,7.761-9.18,7.761H55.32V26.72Zm4.921-11.736c3.849,0,6.088-1.956,6.088-5.047,0-2.934-2.208-4.386-5.268-4.386H55.32v9.433Z"
              fill="#132859"
            />
            <path
              d="M69.456,26.72V11.2h2.776v2.335c.221-.757,1.546-2.713,3.344-2.713a4.935,4.935,0,0,1,2.65.726l-1.1,2.618A3.29,3.29,0,0,0,75.2,13.47c-1.8,0-2.871,2.051-2.966,3.407V26.72Z"
              fill="#132859"
            />
            <path
              d="M93.875,24.8c-.883,1.167-3.344,2.366-6.53,2.366a7.742,7.742,0,0,1-8.14-8.108c0-4.827,3.25-8.266,7.982-8.266,4.859,0,7.161,3.818,7.161,7.919v.82H82.013a5.825,5.825,0,0,0,.1.947c.568,3.123,3.06,4.385,5.836,4.385A6.75,6.75,0,0,0,92.9,22.935Zm-2.334-7.572c-.41-2.366-1.7-4.164-4.417-4.164a4.876,4.876,0,0,0-5.016,4.164Z"
              fill="#132859"
            />
            <path
              d="M97.5,23.029a6.052,6.052,0,0,0,4.29,1.957c1.515,0,2.84-.758,2.84-2.556,0-2.871-7.162-3.123-7.162-7.351,0-2.335,1.956-4.322,4.985-4.322a7.057,7.057,0,0,1,4.827,1.8l-1.105,1.956a5.247,5.247,0,0,0-3.375-1.577c-1.641,0-2.429.978-2.429,1.956,0,3,7.129,2.682,7.129,7.477,0,2.934-2.3,4.8-5.584,4.8a8.305,8.305,0,0,1-5.646-2.145Z"
              fill="#132859"
            />
            <path
              d="M112.208,26.72V13.533h-2.871v-2.3h2.871v-5.4h2.776v5.4H118.8v2.3h-3.817V26.72Z"
              fill="#132859"
            />
            <path
              d="M133.473,25.08a10.449,10.449,0,0,1-6.846,2.082c-4.638,0-6.752-2.682-6.752-5.616,0-3.533,3.6-5.016,6.4-5.016a6.966,6.966,0,0,1,4.511,1.515V16.12c0-1.7-1.294-2.966-3.849-2.966a11.222,11.222,0,0,0-4.322.915l-.915-2.081a12.868,12.868,0,0,1,5.395-1.2c5.079,0,6.373,2.4,6.373,5.993Zm-2.682-4.606a6.327,6.327,0,0,0-4.133-1.577c-2.618,0-3.912,1.356-3.912,2.87,0,1.578,1.388,3.029,4.07,3.029a7,7,0,0,0,3.975-1.073Z"
              fill="#132859"
            />
            <path
              d="M142.465,27.036,135.3,11.2h3.028l3.881,9.118a15.978,15.978,0,0,1,.852,2.334h.031a17.342,17.342,0,0,1,.82-2.334l3.818-9.118h3l-7.066,15.838Z"
              fill="#132859"
            />
            <path
              d="M152.594,6.372a1.89,1.89,0,0,1,1.956-1.957,1.847,1.847,0,0,1,1.924,1.957A1.873,1.873,0,0,1,154.55,8.3a1.9,1.9,0,0,1-1.956-1.924m.536,4.827h2.808V26.721H153.13Z"
              fill="#132859"
            />
            <path
              d="M26.955,29.053a3.932,3.932,0,0,1,.567.324,3.591,3.591,0,0,1,.558.479c.045.047.149.17.227.139s.047-.161.023-.216a1.318,1.318,0,0,0-.359-.486,2.6,2.6,0,0,0-.379-.253c-.049-.029-.325-.142-.224-.232.046-.042.217,0,.277.008a1.729,1.729,0,0,1,.969.516c.044.047.1.1.162.084.142-.04-.008-.262-.053-.317a2.073,2.073,0,0,0-.305-.3c-.108-.088-.233-.151-.344-.233a4.555,4.555,0,0,0-.8-.449,6.491,6.491,0,0,0-.985-.362,1.052,1.052,0,0,1-.6-.5,1.123,1.123,0,0,0-.273-.313,3.767,3.767,0,0,1-.309-.255q-.314-.264-.625-.53l-.7-.594a5.025,5.025,0,0,1-.558-.491c-.117-.136-.074-.246-.047-.4l.054-.314-1.157-.111c-.02.126-.041.253-.061.379a.528.528,0,0,0-.016.255.52.52,0,0,0,.157.195l.849.813.121.119a8.078,8.078,0,0,0,.755.737,10.838,10.838,0,0,1,.969.858,1.119,1.119,0,0,1,.211.213c-.04.022-.134-.019-.179-.028-.068-.013-.138-.023-.207-.03a10.151,10.151,0,0,0-1.52-.039c-.035,0-.077.009-.089.042a.061.061,0,0,0,.02.062.19.19,0,0,0,.06.031c.117.044.235.085.353.125s.208.03.314.056l.236.052c.319.071.635.153.95.241a10.782,10.782,0,0,1,1.344.466c.2.087.409.172.609.268"
              fill="#132859"
            />
            <path
              d="M31.473,28.8c.507.1,1.012.2,1.524.284a1.982,1.982,0,0,1,.327.1,2.689,2.689,0,0,0,.317.138c.178.038.049-.246-.013-.312a.849.849,0,0,0-.376-.2,5.687,5.687,0,0,0-.614-.155c-.1-.019-.552-.029-.578-.128-.063-.241.543-.151.645-.137a3.094,3.094,0,0,1,1.093.195c.044.024.1.051.139.021.179-.135-.245-.431-.339-.476a3.606,3.606,0,0,0-.684-.194q-.39-.1-.775-.2a3.668,3.668,0,0,1-1.018-.329,4.492,4.492,0,0,0-.877-.466c-.569-.221-1.487-.624-1.689-.716-.418-.191-.823-.42-1.231-.631l-.738-.383-.325-.169a3.5,3.5,0,0,1-.372-.188c-.167-.114-.116-.305-.125-.485L25.747,24l-1.835.147a1.02,1.02,0,0,0,.106.542,1.189,1.189,0,0,0,.519.368c.109.058.221.113.334.166l.193.091c.041.02.12.078.165.078l1.583.747,1.472.694a11.12,11.12,0,0,1,1.063.458c.37.225.717.528,1.071.783l-1.242-.031a1.327,1.327,0,0,0-.534.033,1.311,1.311,0,0,0-.263.136c-.021.015-.112.067-.113.1,0,.067.431.092.5.1q.312.027.623.063.623.071,1.24.175c.281.048.56.1.839.155"
              fill="#132859"
            />
            <path
              d="M34.876,15.63a9.892,9.892,0,0,1-.743,2.312A10.436,10.436,0,0,1,30.9,21.65a42.794,42.794,0,0,1-4.464,2.459,4.407,4.407,0,0,1-2.35.33c-.513-.073-1.174-.182-1.174.038s-.294-.146-.587,0-.368.586-.624.33-.771-.918-1.432-.992a10.25,10.25,0,0,0-2.79-.477c-.492.012-.985.009-1.477,0a17.416,17.416,0,0,0-1.814.023.085.085,0,0,1-.039,0,.03.03,0,0,1-.021-.03c0-.013.015-.02.026-.026.16-.087.32-.17.479-.257.07-.038.149-.071.217-.112a.025.025,0,0,0,.013-.027c-.008-.024-.151-.007-.175-.007-.075,0-.151,0-.226.008-.178.007-.355.019-.531.039a2.218,2.218,0,0,0-.343.06,5.4,5.4,0,0,1-1.064.294c-.147-.037.184-.551.184-.551a3.389,3.389,0,0,1-.735.367c-.146,0,0-.4,0-.4C10.87,23.008,1.694,27.559,1,27.6s-1.321-.11-.807-.55a50.829,50.829,0,0,1,5.5-3.524c1.75-.7,3.456-1.525,5.173-2.3l1.014-.458c.3-.136.568-.4.865-.5a5.636,5.636,0,0,1,1.426-.071q.75-.034,1.5-.078,1.5-.09,3-.222,3.01-.264,6-.7c.5-.072.994-.171,1.494-.229a4.5,4.5,0,0,0,1.028-.13,6.351,6.351,0,0,0,2.079-1.173,6.2,6.2,0,0,0,1.1-1.2,2.7,2.7,0,0,1,.681-.8,2.045,2.045,0,0,1,.9-.256,11.641,11.641,0,0,1,2.913.058Z"
              fill="#265dde"
            />
            <path
              d="M19.955,15.722S16.621,17.27,16.07,17.71s-2.606,1.725-2.973,2.129-.587.624-.587.624.4.367,1.872,0a41.98,41.98,0,0,1,5.506-.367c1.248-.11,4.771-.514,5.285-.661a21.179,21.179,0,0,0,2.937-.513c.58-.452,1.192-.855,1.757-1.328.428-.36,1.574-1.195,1.418-1.879-.076-.332-.327-.3-.595-.207-.58.211-1.165.408-1.76.574a11.024,11.024,0,0,1-3.572.462,12.174,12.174,0,0,1-3.185-.693c-.255-.086-.508-.177-.761-.269a2.4,2.4,0,0,0-.725-.258c-.235.014-.519.3-.732.4"
              fill="#1f49ab"
            />
            <path
              d="M40.32,8.622a1.427,1.427,0,0,1-.2.071,6.368,6.368,0,0,1-2.076.181c-.486.005-.98.03-1.466,0-.268-.017-.36.32-.473.495a4.028,4.028,0,0,1-.571.643c-.367.367-.4,1.1-.515,2.02a10.436,10.436,0,0,0,.11,2.2,2.972,2.972,0,0,1-.257,1.394c-.11.294-2.349.367-3.157.221a4.935,4.935,0,0,0-2.956.735c-.515.44-2.257.035-4.348,0a7.469,7.469,0,0,1-3.781-1.248,11.875,11.875,0,0,0,1.321-.661c.772-.441,3.452-2.056,4.37-2.717a12.586,12.586,0,0,0,2.367-2.808,3.38,3.38,0,0,0,.455-1.666,10.968,10.968,0,0,0,.011-1.71,9.557,9.557,0,0,0-.928-2.7A10.363,10.363,0,0,1,27.8,1.714,4.564,4.564,0,0,1,27.686,1c-.016-.221.02-.319.255-.194a2.666,2.666,0,0,0,.478.245,2.6,2.6,0,0,0-.272-.467c-.03-.061-.219-.33-.1-.395.038-.022.432.225.44.2.028-.107-.041-.362.126-.383a.336.336,0,0,1,.227.092L29.5.585a12.506,12.506,0,0,0,1.255.844l.317.181c.072.042.207.158.289.166.207.018.081-.347.315-.121.981.947,1.942,1.915,2.93,2.854.752.714,1.724,1.172,2.049,1.6s.226.7.226.7l.588.294,1.578.624a5.544,5.544,0,0,1,1.285.5.255.255,0,0,1,.091.307.218.218,0,0,1-.1.1"
              fill="#132859"
            />
          </g>
        </svg>
        <div>
          <span>API</span>
        </div>
      </div>
    </Link>
  )
}

export default Logo
