import React, { MutableRefObject } from "react"
import Logo from "../Sidebar/Logo"
import MobileMenuIcon from "./MobileMenuIcon"
import { animateMobileMenuVisibility } from "../Main"

const styles = require("./Header.module.scss");

interface Props {
	showMobileMenu: boolean;
	mobileMenuRef: MutableRefObject<HTMLDivElement | null>;
	toggleMobileMenu: () => void;
}

const Header: React.FunctionComponent<Props> = ({mobileMenuRef, showMobileMenu, toggleMobileMenu}) => {
	return (
		<div className={styles.Header}>
			<div className={styles.Logo}>
				<Logo />
			</div>
			<div className={styles.Links}>
				<div className={styles.Link}>
					<a target="_blank" href="https://prestavi.com">Website</a>
				</div>
				<div className={styles.Link}>
					<a target="_blank" href="https://app.prestavi.com/login">Log in</a>
				</div>
				<MobileMenuIcon
					toggleMobileMenu={() => {
						mobileMenuRef = animateMobileMenuVisibility(showMobileMenu, mobileMenuRef);
						toggleMobileMenu();
					}}
				/>
			</div>
		</div>
	);
};

export default Header;
