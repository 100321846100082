import React from "react"
import Logo from "./Logo"
import SidebarContent from "./SidebarContent"

const styles = require("./Sidebar.module.scss");

interface Props {
	currentPageId: string;
}

const Sidebar: React.FunctionComponent<Props> = ({currentPageId}) => {

	return (
		<div className={styles.Sidebar}>
			<Logo />

			<SidebarContent currentPageId={currentPageId} />
		</div>
	);
};

export default Sidebar;
