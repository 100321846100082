import React, { MutableRefObject, ReactChildren, useRef, useState } from "react"
import Sidebar from "./Sidebar/Sidebar"
import Header from "./Header/Header"
import MobileMenu from "../MobileMenu/MobileMenu"

const styles = require("./Layouts.module.scss");

interface Props {
  children: ReactChildren
  pageContext: {
    [key: string]: any
  }
  location: {
    pathname: string
  }
}

function calculatePageId(resourcePageId: string|undefined, pathname: string) {
  if (resourcePageId) {
    return resourcePageId;
  }

  return pathname;
}

export function animateMobileMenuVisibility(showingMobileMenu: boolean, mobileMenuRef: MutableRefObject<HTMLDivElement | null>): MutableRefObject<HTMLDivElement | null>  {
  if (!mobileMenuRef.current) return mobileMenuRef;

  if (showingMobileMenu) {
    mobileMenuRef.current.style.transform = "translateX(-500px)";
  } else {
    mobileMenuRef.current.style.transform = "translateX(0px)";
  }

  return mobileMenuRef;
}

const Main: React.FunctionComponent<Props> = ({children, pageContext, location}) => {
  const [showingMobileMenu, setShowingMobileMenu] = useState(false);
  let mobileMenuRef: MutableRefObject<HTMLDivElement | null> = useRef(null);


  const currentPageId = calculatePageId(pageContext.id, location.pathname)
  return (
    <div className={styles.Main}>
      <Sidebar currentPageId={currentPageId} />
      <div className={styles.ContentArea}>
        <Header
          showMobileMenu={showingMobileMenu}
          mobileMenuRef={mobileMenuRef}
          toggleMobileMenu={() => setShowingMobileMenu(!showingMobileMenu)}
        />
        {children}
      </div>
      <MobileMenu
        showMobileMenu={showingMobileMenu}
        currentPageId={currentPageId}
        onHide={() => {
          mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
          setShowingMobileMenu(!showingMobileMenu);
        }}
        forwardRef={mobileMenuRef}
      />

      {showingMobileMenu && (
        <div className={styles.Backdrop} onClick={() => {
          mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
          setShowingMobileMenu(!showingMobileMenu);
        }}></div>
      )}
    </div>
  );
};

export default Main;