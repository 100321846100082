import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby"
import { createDisplayName, uppercase } from "../../../utils"
import classNames from "classnames"

const styles = require("./Sidebar.module.scss");

interface Props {
  currentPageId: string;
  onClick?: () => void;
}

const IntroPages = [
  { id: '/', path: '/', name: 'Introduction'},
  { id: '/authentication', path: '/authentication', name: 'Authentication'},
  { id: '/errors', path: '/errors', name: 'Errors'},
  { id: '/pagination', path: '/pagination', name: 'Pagination'},
  { id: '/webhooks', path: '/webhooks', name: 'Webhooks'},
]

const SidebarContent: React.FunctionComponent<Props> = ({currentPageId, onClick}) => {
  const data = useStaticQuery(graphql`
		query {
			allFile(sort: {fields: name}) {
				nodes {
					id
					name
					relativeDirectory
				}
			}
		}
	`)

  interface Results {
    [type: string]: Array<{
      id: string;
      path: string;
      name: string;
    }>
  }
  interface Page {
    id: string;
    name: string;
    relativeDirectory: string;
  }
  const pages: Results = data.allFile.nodes.reduce((results: Results, page: Page) => {
    if (results[page.relativeDirectory] === undefined) {
      results[page.relativeDirectory] = [];
    }

    const uri = page.name.match(/\d+-(\w.+)/);
    if (!uri) {
      return results;
    }

    let name = createDisplayName(uri[1]);

    results[page.relativeDirectory].push({
      id: page.id,
      path: `/docs/${page.relativeDirectory}/${uri[1]}`,
      name,
    })

    return results;
  }, {});

  const resources = Object.keys(pages).map((type) => {

    const items = pages[type].map((page) => {
      const classes = classNames([
        styles.PageLink,
        page.id === currentPageId && styles.SelectedPage
      ])

      return (
        <div className={classes} key={page.id}>
          <Link onClick={onClick} to={page.path}>{page.name}</Link>
        </div>
      )
    })

    return (
      <div className={styles.Resource} key={type}>
        <h3>{uppercase(type.replace("-", " "))}</h3>
        <div>
          {items}
        </div>
      </div>
    )
  })

  const introPages = IntroPages.map((page) => {
    const classes = classNames([
      styles.PageLink,
      page.id === currentPageId && styles.SelectedPage
    ]);

    return (
      <div className={classes} key={page.id}>
        <Link onClick={onClick} to={page.path}>{page.name}</Link>
      </div>
    )
  });

	return (
		<React.Fragment>
      <div className={styles.IntroPages}>
        {introPages}

      </div>

      <h2>Resources</h2>
      {resources}
    </React.Fragment>
	);
};

export default SidebarContent;
