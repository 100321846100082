import React from 'react';
import Main from "./src/components/Layouts/Main"
import './src/styles/index.css';
import Helmet from "react-helmet"

export const wrapPageElement = ({ element, props }) => {
    return (
      <Main {...props}>
          <Helmet>
              <title>Prestavi API Docs</title>
              <meta name="description" content="The complete reference documentation for the Prestavi API. Use the API to programmatically access several key areas of the system."/>
          </Helmet>
          {element}
      </Main>
    )
}