import { CodeBlockTheme } from "@atlaskit/code"

export const CodeBlockDarkTheme: CodeBlockTheme = {
  lineNumberColor: "#f0f3fc",
  lineNumberBgColor: "#07163B",
  backgroundColor: "#132859",
  propertyColor: "#f0f3fc",
  punctuationColor: "#9cb7f7",
  booleanColor: "#f0f3fc",
  numberColor: "#f0f3fc",
  stringColor: "#bfd0f7",
  metaColor: "#f0f3fc",
  symbolColor: "#f0f3fc",
  constantColor: "#f0f3fc",
  charColor: "#f0f3fc",
  prologColor: "#f0f3fc",
  literalColor: "#f0f3fc",
  attributeColor: "#f0f3fc",
  builtInColor: "#f0f3fc",
  operatorColor: "#f0f3fc",
  typeColor: "#f0f3fc",
  importantColor: "#f0f3fc",
  codeColor: "#f0f3fc",
  sectionColor: "#f0f3fc",
  codeFontSize: 14,
  codeLineHeight: 1.6,
};

export function uppercase(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function createDisplayName(uri: string) {
  const parts = uri.split("-");

  return parts.reduce((result: string, part: string) => {
    if (part === "a") {
      result += " a ";

      return result;
    }

    result+= uppercase(`${part} `);

    return result;
  }, "");
}