// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layouts-resource-tsx": () => import("./../../../src/components/Layouts/Resource.tsx" /* webpackChunkName: "component---src-components-layouts-resource-tsx" */),
  "component---src-pages-authentication-tsx": () => import("./../../../src/pages/authentication.tsx" /* webpackChunkName: "component---src-pages-authentication-tsx" */),
  "component---src-pages-errors-tsx": () => import("./../../../src/pages/errors.tsx" /* webpackChunkName: "component---src-pages-errors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pagination-tsx": () => import("./../../../src/pages/pagination.tsx" /* webpackChunkName: "component---src-pages-pagination-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-webhooks-tsx": () => import("./../../../src/pages/webhooks.tsx" /* webpackChunkName: "component---src-pages-webhooks-tsx" */)
}

